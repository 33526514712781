html, body, #root {
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background-color: rgb(246, 248, 251);
    color: rgba(0, 0, 0, 0.87);
}

input, select, button {
    font-family: 'DM Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.87);
}

*, *:before, *:after {
    box-sizing: border-box;
}

#root {
}

p {
    margin: 0;
    padding: 0;
}

ul {
    padding: 0;
    margin: 0;
}

a {
    color: #0A84FF;
    text-decoration: none;
    cursor: pointer;
}

button {
    cursor: pointer;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.CodeMirror {
    font-size: 16px;
    line-height: 1.3;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    height: 600px !important;
}
